import React, { useState } from "react";
import "./wizard.css";
import nfWorkflows from "../../../nf-configs/nfworkflows.json";


const steps = {
  initial: {
    questions: [
      {
        question: "Please designate the research methodology and research aim.",
        options: [
          {
            text: "I want to discover variants/polymorphisms on an organism genome/exome",
            button: "Whole genome/exome analysis",
          },
          {
            text: "I want to analyze the expression of genes",
            button: "RNA sequencing",
          },
          {
            text: "I want to compare the expression of genes based on the proteome profile of different experimental groups",
            button: "Proteomic analysis",
          },
          {
            text: "I want to analyze epigenetic patterns among the genome",
            button: "Epigenetics analysis",
          },
        ],
      },
      {
        question: "Please designate the research field",
        options: [
          { text: "", button: "Medical research" },
          { text: "", button: "Veterinary sciences" },
          { text: "", button: "Agriculture" },
          { text: "", button: "Other Biological Species" },
        ],
      },
    ],
  },
  next: {
    heading: "Sequenced Data Source",
    question: "What is the source of your sequenced data?",
    options: [
      { text: "DNA", button: "DNA" },
      { text: "RNA", button: "RNA" },
    ],
  },
  dna: {
    heading: "DNA-seq Data",
    question: "Which of these options were used to generate your DNA-seq data?",
    options: [
      { text: "Bulk DNA-seq", button: "Bulk DNA-seq" },
      { text: "Single-cell DNA-seq", button: "Single-cell DNA-seq" },
      {
        text: "Evaluate the microbiome of samples through genome",
        button: "Metagenomics",
      },
    ],
  },
  bulkDna: {
    heading: "Bulk DNA-seq",
    question: "Are you interested in:",
    options: [
      {
        text: "Assembly and annotate a new genome",
        button: "Genome assembly and annotation pipeline",
      },
      {
        text: "Identify genetic variants, including single nucleotide variants (SNVs), small insertions/deletions (indels) and CNVs compared to a reference genome",
        button: "sarek pipeline (germinative calling)",
        pipeline_name: "sarek",
      },
      {
        text: "Identify genetic variants, including single nucleotide variants (SNVs), small insertions/deletions (indels) and CNVs using paired tumor/normal samples",
        button: "sarek pipeline (somatic calling)",
        pipeline_name: "sarek",
      },
      {
        text: "Detect changes in DNA circularization",
        button: "circdna pipeline (bacteria)",
      },
    ],
  },
  metagenomics: {
    heading: "Metagenomics",
    question: "The genetic material of the microorganisms was sequenced using:",
    options: [
      { text: "Amplicon technique", button: "Ampliseq pipeline" },
      { text: "Shotgun technique", button: "Taxprofiler pipeline" },
    ],
  },
  amplicon: {
    heading: "Metagenomics - Amplicon Technique",
    question: "Do you want to:",
    options: [
      {
        text: "Taxonomically characterize reads from a single sample",
        button: "Taxprofiler pipeline",
        pipeline_name: "taxprofiler",
      },
      {
        text: "Taxonomically characterize from multiple samples and also assess differential abundance of organisms between sample groups",
        button: "Taxprofiler + differential abundance pipeline",
        pipeline_name: "taxprofiler_differential_abundance",
      },
    ],
  },
  rna: {
    heading: "RNA-seq Data",
    question: "Which of these options were used to generate your RNA-seq data?",
    options: [
      {
        text: "I want to compare the expression of genes with different experimental groups at tissue-level",
        button: "Bulk RNAseq",
      },
      {
        text: "I want to analyze the expression of genes based on the trascriptome profile at single-cell level",
        button: "Single-cell RNAseq",
      },
    ],
  },
  bulkRna: {
    heading: "Bulk RNAseq",
    question: "Are you interested in:",
    options: [
      {
        text: "Assessing differential expression gene expression between two or more groups",
        button: "rnaseq + differential abundance pipelines",
        pipeline_name: "skygenic/rnaseq",
      },
      {
        text: "Performing assembly and annotation of mRNAs from a non-model organism",
        button: "De novo transcript assembly pipeline",
        pipeline_name: "denovo_transcript_assembly",
      },
      {
        text: "Detect gene-fusions using transcripts",
        button: "RNAfusion pipeline",
        pipeline_name: "rnafusion",
      },
      {
        text: "Metatranscriptomics - Evaluate microbiome of samples through transcripts",
        button: "Metatranscriptomics",
        pipeline_name: "metatranscriptomics",
      },
    ],
  },
  metatranscriptomics: {
    heading: "Metatranscriptomics",
    question: "Do you want to:",
    options: [
      {
        text: "Taxonomically characterize reads from a single sample",
        button: "Taxprofiler pipeline",
        pipeline_name: "taxprofiler",
      },
      {
        text: "Taxonomically characterize from multiple samples and also assess differential abundance of organisms between sample groups",
        button: "Taxprofiler + differential abundance pipeline",
        pipeline_name: "taxprofiler_differential_abundance",
      },
    ],
  },
};

function AnalysisWizard({ setSelectedWorkflow, setAnalysisStep }) {
  const [currentStep, setCurrentStep] = useState("initial");
  const [selectedMethodology, setSelectedMethodology] = useState(null);
  const [selectedField, setSelectedField] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const stepMappings = {
    "Whole genome/exome analysis": null,
    "RNA sequencing": "rna",
    "Proteomic analysis": null,
    "Epigenetics analysis": null,
    DNA: "dna",
    RNA: "rna",
    "Bulk DNA-seq": "bulkDna",
    "Single-cell DNA-seq": null,
    Metagenomics: "metagenomics",
    "Bulk RNAseq": "bulkRna",
    "Single-cell RNAseq": null,
    Metatranscriptomics: "metatranscriptomics",
    "Taxonomically characterize reads from a single sample": null,
    "Taxonomically characterize from multiple samples and also assess differential abundance of organisms between sample groups":
      null,
  };

  const handleNext = () => {
    if (currentStep === "initial") {
      setCurrentStep("next");
    } else {
      const nextStep = stepMappings[selectedMethodology || selectedField];
      if (nextStep) {
        setCurrentStep(nextStep);
        
      }
    }
    if (selectedOption && selectedOption.pipeline_name) {
      
      const workflow = nfWorkflows.workflows[selectedOption.pipeline_name];
      if (workflow) {
      setSelectedWorkflow(workflow);
      setAnalysisStep(2);
      }
      console.log("selectedPipeline", selectedOption.pipeline_name, workflow);
    }
  };

  const handleBack = () => {
    const previousStepMap = {
      next: "initial",
      dna: "next",
      bulkDna: "dna",
      metagenomics: "dna",
      amplicon: "metagenomics",
      rna: "next",
      bulkRna: "rna",
      metatranscriptomics: "bulkRna",
    };

    setCurrentStep(previousStepMap[currentStep] || "initial");
    setSelectedMethodology(null);
    setSelectedField(null);
  };

  const handleMethodologyClick = (option) => {
    setSelectedMethodology(option.button);
    setSelectedOption(option);
  };

  const handleFieldClick = (button) => {
    setSelectedField(button);
  };

  const stepData = steps[currentStep] || {};

  return (
    <div className="wizard-App">
      <h1 className="wizard-Heading">Analysis Wizard</h1>
      <hr className="wizard-heading-line" />
      <div className="wizard-container">
        <div className="wizard-step">
          <div className="wizard-Headings">{stepData.heading}</div>
          <p className="wizard-questions">{stepData.question}</p>
          <div className="wizard-options">
            {currentStep === "initial" ? (
              steps.initial.questions.map((questionData, index) => (
                <div key={index} className="wizard-question-block">
                  <p className="wizard-questions">{questionData.question}</p>
                  <div className="wizard-options">
                    {questionData.options.map((option, optIndex) => (
                      <div
                        key={optIndex}
                        className={`wizard-option ${
                          (index === 0 &&
                            selectedMethodology === option.button) ||
                          (index === 1 && selectedField === option.button)
                            ? "selected"
                            : ""
                        }`}
                        onClick={() =>
                          index === 0
                            ? handleMethodologyClick(option)
                            : handleFieldClick(option.button)
                        }
                      >
                        {option.text && <p>{option.text}</p>}
                        <button
                          className="wizard-option-button wizard-button "
                          onClick={() =>
                            index === 0
                              ? handleMethodologyClick(option)
                              : handleFieldClick(option.button)
                          }
                        >
                          {option.button}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <div className="wizard-question-block">
                <div className="wizard-options">
                  {stepData.options.map((option, optIndex) => (
                    <div
                      key={optIndex}
                      className={`wizard-option ${
                        selectedMethodology === option.button ? "selected" : ""
                      }`}
                      onClick={() => handleMethodologyClick(option)}
                    >
                      {option.text && <p>{option.text}</p>}
                      <button
                        className="wizard-option-button wizard-button "
                        onClick={() => handleMethodologyClick(option)}
                      >
                        {option.button}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="wizard-navigation-buttons ">
          <button
            className=" wizard-button"
            onClick={handleBack}
            disabled={currentStep === "initial"}
          >
            Back
          </button>
          <button
            className=" wizard-button"
            onClick={handleNext}
            disabled={
              (currentStep === "initial" &&
                (!selectedMethodology || !selectedField)) ||
              (currentStep !== "initial" && !selectedMethodology)
            }
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default AnalysisWizard;
