import React, { useState, useEffect, useMemo } from "react";
import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
import DataTable from "react-data-table-component";
import moment from "moment";
import { MaterialReactTable } from "material-react-table";
import { Co2Sharp } from "@mui/icons-material";

const ReferenceFile = ({
  plf,
  setReferenceFileOut,
  selectedRootWorkflow,
  workflow,
}) => {
  const [showRefModal, setShowRefModal] = useState(false);
  const [referenceFiles, setReferenceFiles] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [handleRefSelect, setHandleRefSelect] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [referenceType, setReferenceType] = useState(undefined);
  const [referenceFastaOptions, setReferenceFastaOptions] = useState([{}]);
  const [referenceGTFOptions, setReferenceGTFOptions] = useState([{}]);
  const [referenceIDXOptions, setReferenceIDXOptions] = useState([{}]);
  // const [referenceStarOptions, setReferenceStarOptions] = useState([{}]);
  const [referenceFasta, setReferenceFasta] = useState(null);
  const [referenceGtf, setReferenceGtf] = useState(null);
  const [referenceIDX, setReferenceIDX] = useState(null);

  // const [referenceStardb, setReferenceStardb] = useState({
  //   value: "",
  //   label: "",
  // });

  const [stepInfo, setStepInfo] = useState(null);

  useEffect(() => {
    if (!selectedRootWorkflow || !workflow) return;
    const step = selectedRootWorkflow.steps.find(
      (step) => step.name === "Reference File"
    );
    const program = workflow.programType;
    setStepInfo(step.programs[program]);
  }, [selectedRootWorkflow, workflow]);

  const getReferenceOptions = (referencetype) => {
    try {
      const _default_reference = workflow?.inputs.find((x) =>
        referencetype.test(x.name)
      ).default_reference;

      const _referenceGeneOptions = Object.entries(_default_reference).map(
        ([key, value]) => ({
          label: key.split("---")[1],
          value: value[0],
        })
      );

      return _referenceGeneOptions;
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!workflow) {
      return;
    }
    setReferenceFastaOptions(getReferenceOptions(/reference_fasta/));
    setReferenceGTFOptions(getReferenceOptions(/gtf/));
    setReferenceIDXOptions(getReferenceOptions(/reference_stardb|idx|reference_idx/));
  }, [workflow]);

  useEffect(() => {
    if (!referenceType) {
      return;
    }
    console.log(referenceType);
    setReferenceFiles(
      plf
        ? plf.filter(
            (file) => referenceType.test(file.file_name)
            // ||
            // referenceType.toString() === /fastfa/.toString() ?
            // /\.fa(?!stq)/.test(file.file_name) : null
          )
        : []
    );
  }, [plf, referenceType]);

  useEffect(() => {
    setReferenceFileOut({
      gtf: referenceGtf,
      fasta: referenceFasta,
      idx: referenceIDX,
    });
  }, [referenceFasta, referenceGtf, referenceIDX]);

  // useEffect(() => {
  //   const filtered = plf?.filter((x) =>
  //     x.file_name.toLowerCase().includes("gtf")
  //   );
  //   setFilteredFiles(filtered);
  // }, [plf]);

  function round(num, decimalPlaces = 0) {
    num = Math.round(num + "e" + decimalPlaces);
    return Number(num + "e" + -decimalPlaces);
  }

  const inputColumns = useMemo(() => [
    {
      header: "Name",
      accessorKey: "file_name",
      sortable: true,
      format: ({ file_name, file_color }) => (
        <span
          className="file"
          style={{
            "--color": `#${file_color}`,
            "--hover-color": file_color === "ffffff" ? "" : `#${file_color}`,
            "--margin": file_color === "ffffff" ? "10px" : `5px`,
          }}
        >
          {file_name}
        </span>
      ),
    },
    // {
    //   name: "Exp Dsgn Tags",
    //   accessorKey: "tags",
    //   format: ({ tags }) => (
    //     <span style={{ margin: "5px" }}>
    //       {tags.map((tag, index) => (
    //         <span className="tag" key={index}>
    //           {tag}
    //         </span>
    //       ))}
    //     </span>
    //   ),
    //   wrap: true,
    // },
    {
      header: "Created",
      accessorKey: "date_created",
      sortable: true,
      Cell: ({ renderedCellValue, row }) => (
        <div>{moment.unix(renderedCellValue).format("MM/DD/YYYY")}</div>
      ),
    },
    {
      header: "Type",
      accessorKey: "file_type",
      sortable: true,
    },
    {
      header: "Size",
      accessorKey: "file_size",
      sortable: true,
      Cell: ({ renderedCellValue, row }) => (
        // renderedCellValue is in bytes, convert to Bytes, MB & GB

        <div>
          {renderedCellValue === null
            ? ""
            : renderedCellValue < 1000
            ? `${renderedCellValue} Bytes`
            : renderedCellValue < 1000000
            ? `${round(renderedCellValue / 1000, 2)} KB`
            : renderedCellValue < 1000000000
            ? `${round(renderedCellValue / 1000000, 2)} MB`
            : `${round(renderedCellValue / 1000000000, 2)} GB`}
        </div>
      ),
    },
    {
      header: "Tags",
      accessorKey: "tags",
      format: ({ tags }) => (
        <span style={{ margin: "5px" }}>
          {tags.map((tag, index) => (
            <span className="tag" key={index}>
              {tag}
            </span>
          ))}
        </span>
      ),
      wrap: true,
    },
  ]);

  const handleCloseInputsModal = () => {
    setShowRefModal(false);
    let selectedRow = Object.keys(rowSelection);
    selectedRow = parseInt(selectedRow[0]);

    let x = referenceFiles[selectedRow];
    if (!x) return;

    if (referenceType.toString().includes("gtf")) {
      setReferenceGtf({
        value: `gs://${x.bucket_id}/uploads/${x.file_path}`,
        label: x.file_name,
        rowSelection,
      });
    } else if (referenceType.toString() === /fastfa/.toString()) {
      setReferenceFasta({
        value: `gs://${x.bucket_id}/uploads/${x.file_path}`,
        label: x.file_name,
        rowSelection,
      });
    } else {
      setReferenceIDX({
        value: `gs://${x.bucket_id}/uploads/${x.file_path}`,
        label: x.file_name,
        rowSelection,
      });
    }
  };

  return (
    <>
      <div
        className="analysis-reference-block"
        style={{
          display: "flex",
        }}
      >
        <div className="text-block-50">{stepInfo}</div>
        <div className="div-block-95-copy">
          <div
            className="div-block-96"
            style={{
              display: workflow?.inputs.some((item) =>
                item.name.includes("reference_fasta")
              )
                ? "flex"
                : "none",
            }}
          >
            <div
              className="text-block-52"
              style={{
                display: "block",
              }}
            >
              Reference Fasta - <br />
              <b>{referenceFasta?.value.split("/").pop()}</b>
            </div>
            {handleRefSelect.length > 0 ? (
              <div
                className="reference-dropdown"
                onClick={() => setShowRefModal(true)}
              >
                {handleRefSelect?.selectedRows[0]?.file_name}
              </div>
            ) : (
              ""
            )}
            <div className="div-block-98">
              <Select
                defaultValue={referenceFasta}
                // value={filterState.column}
                onChange={setReferenceFasta}
                options={referenceFastaOptions}
                // getOptionValue={(gene) => gene[1]}
                // getOptionLabel={(gene) => (
                //   <div
                //     style={
                //       {
                //         // fontSize: 21,
                //       }
                //     }
                //   >
                //     {gene}
                //   </div>
                // )}
                className={"reference-dropdown"}
                styles={{
                  width: "100%",
                }}
                isDisabled={handleRefSelect.length === 0 ? false : true}
                // classNamePrefix={'dropdown-item"'}
                placeholder="Reference Files"
                menuPosition="fixed"
                menuPlacement="auto"
              />

              <div
                // className="text-block-53"
                className="btn-1 type-2"
                style={{
                  marginLeft: "14px",
                }}
                onClick={() => {
                  setShowRefModal(true);
                  setReferenceType(/fastfa|.(fa|fa\.|fa\.gz)$/);
                  if (referenceFasta?.rowSelection) {
                    setRowSelection(referenceFasta?.rowSelection);
                  }
                }}
              >
                <div
                  className="btn-1-text"
                  style={{
                    fontSize: "14px",
                  }}
                >
                  Choose From <br />
                  Storage
                </div>
              </div>
            </div>
          </div>
          <div
            className="div-block-96"
            style={{
              display: workflow?.inputs.some((item) =>
                item.name.includes("gtf")
              )
                ? "flex"
                : "none",
            }}
          >
            <div
              className="text-block-52"
              style={{
                display: "block",
              }}
            >
              Reference GTF - <br />
              <b> {referenceGtf?.value.split("/").pop()}</b>
            </div>
            {handleRefSelect.length > 0 ? (
              <div
                className="reference-dropdown"
                onClick={() => setShowRefModal(true)}
              >
                {handleRefSelect?.selectedRows[0]?.file_name}
              </div>
            ) : (
              ""
            )}
            <div className="div-block-98">
              <Select
                value={referenceGtf}
                // value={filterState.column}
                onChange={setReferenceGtf}
                options={referenceGTFOptions}
                className={"reference-dropdown"}
                styles={{
                  width: "100%",
                }}
                isDisabled={handleRefSelect.length === 0 ? false : true}
                // classNamePrefix={'dropdown-item"'}
                placeholder="Reference Files"
                menuPosition="fixed"
                menuPlacement="auto"
              />

              <div
                // className="text-block-53"
                className="btn-1 type-2"
                style={{
                  marginLeft: "14px",
                }}
                onClick={() => {
                  setShowRefModal(true);
                  setReferenceType(/gtf/);
                  if (referenceGtf?.rowSelection) {
                    setRowSelection(referenceGtf?.rowSelection);
                  }
                }}
              >
                <div
                  className="btn-1-text"
                  style={{
                    fontSize: "14px",
                  }}
                >
                  Choose From <br />
                  Storage
                </div>
              </div>
            </div>
          </div>

          <div
            className="div-block-96"
            style={{
              display: workflow?.inputs.some((item) =>
                item.name.includes("reference_idx")
              )
                ? "flex"
                : "none",
            }}
          >
            <div
              className="text-block-52"
              style={{
                display: "block",
              }}
            >
              Reference IDX - <br />
              <b> {referenceIDX?.value.split("/").pop()}</b>
            </div>
            {handleRefSelect.length > 0 ? (
              <div
                className="reference-dropdown"
                onClick={() => setShowRefModal(true)}
              >
                {handleRefSelect?.selectedRows[0]?.file_name}
              </div>
            ) : (
              ""
            )}
            <div className="div-block-98">
              <Select
                value={referenceIDX}
                // value={filterState.column}
                onChange={setReferenceIDX}
                options={referenceIDXOptions}
                className={"reference-dropdown"}
                styles={{
                  width: "100%",
                }}
                isDisabled={handleRefSelect.length === 0 ? false : true}
                // classNamePrefix={'dropdown-item"'}
                placeholder="Reference Files"
                menuPosition="fixed"
                menuPlacement="auto"
              />
              <div
                // className="text-block-53"
                className="btn-1 type-2"
                style={{
                  marginLeft: "14px",
                }}
                onClick={() => {
                  setShowRefModal(true);
                  setReferenceType(/stardb|idx/i);
                  if (referenceIDX?.rowSelection) {
                    setRowSelection(referenceIDX?.rowSelection);
                  }
                }}
              >
                <div
                  className="btn-1-text"
                  style={{
                    fontSize: "14px",
                  }}
                >
                  Choose From <br />
                  Storage
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        dialogClassName="modal-90w"
        show={showRefModal}
        onHide={handleCloseInputsModal}
        keyboard={true}
        // fullscreen={true}
        centered
        size="lg"
        style={{
          overflowY: "auto",
        }}
      >
        <>
          <div
            className="text-block-2"
            style={{
              padding: "30px 40px",
              alignSelf: "center",
            }}
          >
            Reference Files
          </div>

          <div
            style={{
              width: "100%",
              padding: "10px 50px",
            }}
          >
            <MaterialReactTable
              columns={inputColumns}
              data={referenceFiles}
              // isLoading={loadingFiles}
              enableGrouping
              enableStickyHeader
              enableRowSelection
              enableMultiRowSelection={false} //shows radio buttons instead of checkboxes
              muiSearchTextFieldProps={
                {
                  // placeholder: "Search Files",
                  // sx: { minWidth: "18rem" },
                  // variant: 'outlined',
                }
              }
              initialState={{
                density: "compact",
              }}
              options={{
                selectableRowsHighlight: true,
                rowsPerPage: 10,
                rowsPerPageOptions: [10, 20, 30, 40, 50],
                pagination: true,
                paginationComponentOptions: {
                  rowsPerPageText: "Rows per page:",
                  rangeSeparatorText: "of",
                  noRowsPerPage: false,
                  selectAllRowsItem: false,
                  selectAllRowsItemText: "All",
                },
                headerProps: {
                  style: { whiteSpace: "normal", wordWrap: "break-word" },
                },
              }}
              onRowSelectionChange={setRowSelection}
              state={{ rowSelection }}
              // muiTableBodyRowProps={({ row }) => ({
              //   onClick: row.getToggleSelectedHandler(),
              //   sx: { cursor: 'pointer' },
              // })}
              // getRowId={(originalRow) => originalRow._id}
            />
          </div>
        </>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseInputsModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReferenceFile;
