import { v4 as uuidv4 } from "uuid";
import { analysisCreate, startNFAnalysis } from "../../../actions/projects";
import axios from "axios";
import { toast } from "react-toastify";


const uploadFile = async (file, user_bucket, filePath) => {
  console.log("Upload function", filePath, file);
  let inputformData = new FormData();
  inputformData.append("file", file);
  inputformData.append("bucketName", user_bucket);
  inputformData.append("filePath", filePath);

  var config = {
    method: "post",
    // url: "http://localhost:8080/upload",
    url: "https://sg-storage-uploader-dot-data-science-siatik.ew.r.appspot.com/upload",
    data: inputformData,
  };

  try {
    const response = await axios(config);
    console.log(response);
    console.log("File uploaded done", user_bucket, filePath, file);
  } catch (error) {
    console.log(error);
  }
};

const startAnalysis = async (
  selectedWorkflow,
  inputFileOut,
  inputFile2Out,
  referenceFileOut,
  expDesignOut,
  comparisonSelectionOut,
  vmSpeed,
  otherSettingsOut,
  dispatch,
  currentUser,
  params,
  selectedPipeline
) => {
  let run_id = uuidv4();

  console.log("selectedWorkflow", selectedWorkflow);
  console.log("inputFileOut", inputFileOut);
  console.log("inputFile2Out", inputFile2Out);
  console.log("referenceFileOut", referenceFileOut);
  console.log("expDesignOut", expDesignOut);
  console.log("comparisonSelectionOut", comparisonSelectionOut);
  console.log("vmSpeed", vmSpeed);
  console.log("otherSettingsOut", otherSettingsOut);

  try {
    let _params = [];
    const user_bucket = `skygenic-user-${currentUser.id}-standard-1`;

    // Loop over workflow steps, ignore if step is "summary" or "others"
    let steps = Object.keys(selectedWorkflow.steps);
    steps = steps.filter((step) => step !== "summary");

    for (let step of steps) {
      console.log("step", step);
      if (step === "input") {
        if (inputFile2Out) {
          let input_file_path = "";
          if (inputFile2Out.full_file_path.split("/")[0] === "uploads") {
            input_file_path = `gs://${inputFile2Out.bucket_id}/uploads/${inputFile2Out.imaginary_file_path}`;
          } else {
            console.log(inputFile2Out.full_file_path.split("/")[0])
            input_file_path = `gs://${inputFile2Out.bucket_id}/${inputFile2Out.imaginary_file_path}`;
          }
          
          _params.push({ input: input_file_path });
        } else {
          let input_csv = "";
          let columns = selectedWorkflow.steps[step].settings.columns;

          // Add CSV header
          input_csv +=
            columns
              .map((column) =>
                column.includes(":") ? column.split(":")[0] : column
              )
              .join(",") + "\n";
          // get all unique sample ids
          let sample_ids = [
            ...new Set(inputFileOut.map((file) => file.paired.split("_")[0])),
          ];
          // each sample id has 1 or 2 fastq files, each sample id gets a row in the csv, sorted by sample id
          sample_ids.sort();
          sample_ids.forEach((sample_id) => {
            let row = columns
              .map((column) => {
                if (column === "sample") {
                  return sample_id;
                } else if (column.includes("fastq_1")) {
                  let file = inputFileOut.find(
                    (file) =>
                      file.paired.split("_")[0] === sample_id &&
                      file.read_num === "1"
                  );
                  return file
                    ? `gs://${file.bucket_id}/uploads/${file.imaginary_file_path}`
                    : "";
                } else if (column.includes("fastq_2")) {
                  let file = inputFileOut.find(
                    (file) =>
                      file.paired.split("_")[0] === sample_id &&
                      file.read_num === "2"
                  );
                  return file
                    ? `gs://${file.bucket_id}/uploads/${file.imaginary_file_path}`
                    : "";
                } else if (column.includes(":")) {
                  return column.split(":")[1];
                } else {
                  return "";
                }
              })
              .join(",");
            input_csv += row + "\n";
          });

          console.log(input_csv);

          var input_blob = new Blob([input_csv], {
            type: "application/csv",
          });
          var input_file = new File([input_blob], "input.csv", {
            type: "application/csv",
          });
          let filePath = `analysis-uploads/${params.id}/${run_id}`;
          let gres = await uploadFile(input_file, user_bucket, filePath);
          console.log(gres);
          _params.push({
            input: `gs://${user_bucket}/analysis-uploads/${params.id}/${run_id}/input.csv`,
          });
        }
      } else if (step === "experimentalDesign") {
        console.log("expDesignOut", expDesignOut);
        // let contrast_file_path = generateContrastFile(expDesignOut);
        // let diff_abund_input_file_path = generateDiffAbundInputFile(expDesignOut);
        // _params.push({
        //   contrasts: contrast_file_path,
        // });
        // _params.push({
        //   input: `gs://${user_bucket}/analysis-uploads/${params.id}/${run_id}/input.csv`,
        // });
      } else if (step === "reference") {
        // Loop over all settings in referenceFileOut
        referenceFileOut.forEach((refs) => {
          if (refs.file_doc) {
            // GCP storage path
            let file_path = `gs://${refs.file_doc.bucket_id}/uploads/${refs.file_doc.imaginary_file_path}`;
            _params.push({ [refs.name]: file_path });
          } else {
            _params.push({ [refs.name]: refs.value });
          }
        });
      } else if (step === "others") {
        console.log("otherSettingsOut", otherSettingsOut);
        otherSettingsOut.forEach((oss) => {
          console.log("oss", oss);
          if (oss.file_doc) {
            console.log("oss.file_doc", oss.file_doc);
            let file_path = "";
            if (oss.file_doc.full_file_path.split("/")[0] === "uploads") {
              file_path = `gs://${oss.file_doc.bucket_id}/uploads/${oss.file_doc.imaginary_file_path}`;
            } else {
              file_path = `gs://${oss.file_doc.bucket_id}/${oss.file_doc.imaginary_file_path}`;
            }
            _params.push({ [oss.name]: file_path });
          } else {
            console.log("oss.value", oss.value);
            _params.push({ [oss.name]: oss.value });
          }
        });
      }
    }

    console.log(_params);
    
    const nfparams = {
      nextflowPipeline: `../.nextflow/assets/Skygenic/${
        selectedWorkflow.name.split("/")[1]
      }`,
      // release: "main",
      outdir: `gs://${user_bucket}/analysis-runs/${params.id}/${run_id}/outputs`,
      workdir: `gs://${user_bucket}/gcb/workdir/${params.id}/${run_id}`,
      profile: "gcb",
      run_id: run_id,
    };
    _params.forEach((param) => {
      nfparams[Object.keys(param)[0]] = Object.values(param)[0];
    });
    console.log("nfparams", nfparams);

      dispatch(
        startNFAnalysis(params.id, {
          // user_id: "6054074f8c41cc4",
          run_id: run_id,
          nfparams: nfparams,
        })
      );

      dispatch(
        analysisCreate(params.id, {
          // user_id: "6054074f8c41cc4",
          run_id: run_id,
          input: JSON.stringify({ input: 1 }),
          status: "Initiating",
          project_id: params.id,
          pipelineDetails: nfparams,
        })
      );
    toast.success(
      "Analysis has initiated, Please wait couple minutes for the analysis status",
      { autoClose: 8000 }
    );

    // try {
    //   // const response = await axios(config2);
    //   toast.success("Analysis has successful started running.", {
    //     autoClose: 8000,
    //   });
    // } catch (error) {
    //   console.log(error);
    // }
  } catch (error) {
    console.log(error);
    alert("Please fill all the required fields");
  }
};

export default startAnalysis;
