import React, { useState, useEffect, useMemo } from "react";
import { Modal, Button } from "react-bootstrap";
import ExpDesignModal from "../ExpDesignModal";
import DataTable from "react-data-table-component";
import { MaterialReactTable } from "material-react-table";
import moment from "moment";
import axios from "axios";

const ExperimentalDesign = ({
  plf,
  inputFileOut,
  setExpDesign,
  setExpDesignOut,
  selectedPipeline,
}) => {
  const [openExpDesignModal, setOpenExpDesignModal] = useState(false);
  const [metaDataFile, setMetaDataFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [expGenData, setExpGenData] = useState("");
  const [showFilesModal, setShowFilesModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [edCols, setEdCols] = useState([]);
  const [edData, setEdData] = useState([]);
  const [fileLoading, setFileLoading] = useState(false);

  useEffect(() => {
    setFiles(
      plf
        ? plf.filter(
            (file) =>
              file.file_name.includes(".txt") ||
              file.file_name.includes(".tsv") 
              // ||
              // /\.fa(?!stq)/.test(file.file_name)
          )
        : []
    );



    console.log("plf--", plf
    ? plf.filter(
        (file) =>
          file.file_name.includes(".txt") ||
          file.file_name.includes(".tsv") ||
          /\.fa(?!stq)/.test(file.file_name)
      )
    : []);
  }, [plf]);

  function round(num, decimalPlaces = 0) {
    num = Math.round(num + "e" + decimalPlaces);
    return Number(num + "e" + -decimalPlaces);
  }

  const inputColumns = useMemo(() => [
    {
      header: "Name",
      accessorKey: "file_name",
      sortable: true,
      format: ({ file_name, file_color }) => (
        <span
          className="file"
          style={{
            "--color": `#${file_color}`,
            "--hover-color": file_color === "ffffff" ? "" : `#${file_color}`,
            "--margin": file_color === "ffffff" ? "10px" : `5px`,
          }}
        >
          {file_name}
        </span>
      ),
    },
    // {
    //   name: "Exp Dsgn Tags",
    //   accessorKey: "tags",
    //   format: ({ tags }) => (
    //     <span style={{ margin: "5px" }}>
    //       {tags.map((tag, index) => (
    //         <span className="tag" key={index}>
    //           {tag}
    //         </span>
    //       ))}
    //     </span>
    //   ),
    //   wrap: true,
    // },
    {
      header: "Created",
      accessorKey: "date_created",
      sortable: true,
      Cell: ({ renderedCellValue, row }) => (
        <div>{moment.unix(renderedCellValue).format("MM/DD/YYYY")}</div>
      ),
    },
    {
      header: "Type",
      accessorKey: "file_type",
      sortable: true,
    },
    {
      header: "Size",
      accessorKey: "file_size",
      sortable: true,
      Cell: ({ renderedCellValue, row }) => (
        // renderedCellValue is in bytes, convert to Bytes, MB & GB

        <div>
          {renderedCellValue === null
            ? ""
            : renderedCellValue < 1000
            ? `${renderedCellValue} Bytes`
            : renderedCellValue < 1000000
            ? `${round(renderedCellValue / 1000, 2)} KB`
            : renderedCellValue < 1000000000
            ? `${round(renderedCellValue / 1000000, 2)} MB`
            : `${round(renderedCellValue / 1000000000, 2)} GB`}
        </div>
      ),
    },
    {
      header: "Tags",
      accessorKey: "tags",
      format: ({ tags }) => (
        <span style={{ margin: "5px" }}>
          {tags.map((tag, index) => (
            <span className="tag" key={index}>
              {tag}
            </span>
          ))}
        </span>
      ),
      wrap: true,
    },
    {
      header: "Paired",
      accessorKey: "paired",
      sortable: true,
      hide: true,
    },
  ]);

  const tsv2arr = (tsv) => {
    const [headers, ...rows] = tsv
      .trim()
      .split("\n")
      .map((r) => r.split("\t"));
    return rows.reduce(
      (a, r) => [
        ...a,
        Object.assign(
          ...r.map((x, i, _, c = x.trim()) => ({
            [headers[i].trim()]: isNaN(c) ? c : Number(c),
          }))
        ),
      ],
      []
    );
  };

  const handleCloseInputsModal = () => {
    setShowFilesModal(false);
    setFileLoading(true)
    console.log(rowSelection);
    let selectedRow = Object.keys(rowSelection);
    selectedRow = parseInt(selectedRow[0]);
    console.log(files[selectedRow]);

    if (!files[selectedRow]) {
      return;
    }

    setExpDesignOut(`gs://${files[selectedRow].bucket_id}/uploads/${files[selectedRow].imaginary_file_path}`);

    axios
      .post(
        "https://sg-storage-uploader-dot-data-science-siatik.ew.r.appspot.com/download",
        {
          bucketName: files[selectedRow].bucket_id,
          fileName: `uploads/${files[selectedRow].imaginary_file_path}`,
        }
      )
      .then((data) => {
        console.log(data.data);
        axios
          .get(data.data)
          .then((response) => {
            // Split the TSV data into rows
            
            

            let g = response.data.split("\n").map((line) => line.split("\t"));
            setExpDesign(g[0].splice(1));

            const ed_data = tsv2arr(response.data);
            setEdData(ed_data);
            setEdCols(() => {
              let newCols = [];
              response.data
                .split("\n")[0]
                .split("\t")
                .map((cl) => {
                  newCols.push({
                    name: cl,
                    selector: cl,
                    sortable: true,
                  });
                });
              return newCols;
            });

            setFileLoading(true)

          })
          .catch((error) => {
            console.error("Error fetching the TSV data:", error);
          });
        // setEdCols(data.data);
      });
  };

  const changeFileHandler = (_file) => {
    // let _file = event.target.files[0];
    setMetaDataFile(_file);

    const reader = new FileReader();
    reader.onload = function (evt) {
      // console.log(evt.target.result);
      setExpDesignOut(evt.target.result);
      let g = evt.target.result.split("\n").map((line) => line.split("\t"));

      const ed_data = tsv2arr(evt.target.result);
      setEdData(ed_data);
      // setExpDesignOut(ed_data)

      setEdCols(() => {
        let newCols = [];
        evt.target.result
          .split("\n")[0]
          .split("\t")
          .map((cl) => {
            newCols.push({
              name: cl,
              selector: cl,
              sortable: true,
            });
          });
        return newCols;
      });

      setExpDesign(g[0].splice(1));
    };
    reader.readAsText(_file);
  };

  useEffect(() => {
    if (expGenData.length > 0) {
      var myblob = new Blob([expGenData], {
        type: "text/tab-separated-values",
      });
      changeFileHandler(myblob);
    }
  }, [expGenData]);

  return (
    <>
      <div className="text-block-50">
        For the differential expression analysis the experimental design
        identifying each file's conditions and levels must be generated. It can
        be uploaded in .tsv format per the example pop up or generated within
        Skygenic by selecting the experimental design generator.
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "10px",
        }}
      >
        <div className="text-block-42" onClick={() => setShowFilesModal(true)}>
          Choose metadata file
        </div>
        <div class="div-block-102" onClick={() => setShowInfoModal(true)}>
          i
        </div>
      </div>
      {/* <input
        // className="text-block-42"
        className="btn-1 type-2"
        type="file"
        name="file"
        accept=".tsv"
        onChange={(event) => changeFileHandler(event.target.files[0])}
        placeholder="Upload Metadata.tsv"
        style={{
          marginBottom: "20px",
          marginTop: "20px",
          minHeight: "45px",
          paddingTop: "12px",
        }}
      /> */}
      OR
      <div
        className="btn-1 type-2"
        style={{
          width: "300px",
          marginBottom: "30px",
          marginTop: "20px",
        }}
        onClick={() => {
          if (!inputFileOut) {
            alert("Please select some input files to continue.");
            return;
          }
          setOpenExpDesignModal(true);
        }}
      >
        Generate Experimental Design File
      </div>
      <div
        style={{
          width: "80%",
        }}
      >
        <DataTable
          columns={edCols}
          data={edData}
          defaultSortField="file_name"
          pagination
          highlightOnHover
          pointerOnHover
          onRowClicked={(x) => setOpenExpDesignModal(true)}
          progressPending={fileLoading}
          // progressPending={this.state.loadingFiles}
          // progressComponent={<Loader />
          // >}
        />
      </div>
      <ExpDesignModal
        inputFileOut={inputFileOut}
        open={openExpDesignModal}
        setOpenExpDesignModal={setOpenExpDesignModal}
        setExpGenData={setExpGenData}
        selectedPipeline={selectedPipeline}
      />
      <Modal
        dialogClassName="modal-90w"
        show={showFilesModal}
        onHide={handleCloseInputsModal}
        keyboard={true}
        // fullscreen={true}
        centered
        size="lg"
        style={{
          overflowY: "auto",
        }}
      >
        <>
          <div
            className="text-block-2"
            style={{
              padding: "30px 40px",
              alignSelf: "center",
            }}
          >
            Input Files
          </div>

          <div
            style={{
              width: "100%",
              padding: "10px 50px",
            }}
          >
            <MaterialReactTable
              columns={inputColumns}
              data={files}
              // isLoading={loadingFiles}
              enableGrouping
              enableStickyHeader
              enableRowSelection
              enableMultiRowSelection={false} //shows radio buttons instead of checkboxes
              muiSearchTextFieldProps={
                {
                  // placeholder: "Search Files",
                  // sx: { minWidth: "18rem" },
                  // variant: 'outlined',
                }
              }
              options={{
                selectableRowsHighlight: true,
                rowsPerPage: 10,
                rowsPerPageOptions: [10, 20, 30, 40, 50],
                pagination: true,
                paginationComponentOptions: {
                  rowsPerPageText: "Rows per page:",
                  rangeSeparatorText: "of",
                  noRowsPerPage: false,
                  selectAllRowsItem: false,
                  selectAllRowsItemText: "All",
                },
                headerProps: {
                  style: { whiteSpace: "normal", wordWrap: "break-word" },
                },
              }}
              onRowSelectionChange={setRowSelection}
              state={{ rowSelection }}
              // muiTableBodyRowProps={({ row }) => ({
              //   onClick: row.getToggleSelectedHandler(),
              //   sx: { cursor: 'pointer' },
              // })}
              // getRowId={(originalRow) => originalRow._id}
            />
          </div>
        </>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseInputsModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        dialogClassName="modal-90w"
        show={showInfoModal}
        onHide={() => setShowInfoModal(false)}
        keyboard={true}
        // fullscreen={true}
        centered
        size="lg"
        style={{
          overflowY: "auto",
        }}
      >
        <>
          <div className="div-datasample" style={{
            display: "flex",
          }}>
            <div>Provide a .tsv file during upload, here is a sample of it.</div>
            <img
              src="https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/64f93435759c4491c92a9754_sampledata.png"
              loading="lazy"
              sizes="(max-width: 479px) 76vw, (max-width: 991px) 66vw, 636px"
              srcSet="https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/64f93435759c4491c92a9754_sampledata-p-500.png 500w, https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/64f93435759c4491c92a9754_sampledata.png 636w"
              alt=""
            />
          </div>
        </>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowInfoModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ExperimentalDesign;
